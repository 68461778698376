.Home-Page{
  height: 100vh;
}

.Home-Page-header{
  background: black;
  color: #F6A119;
  height: 10%;
  justify-content: center;
  display: flex;
  font-family: Helvetica, monospace;
  font-weight: bold;
  letter-spacing: 0.5em;
  font-size: 16px;
  align-items: center;
  border-bottom: 1px solid #121212;;
}

.Home-Page-banner{
  background: black;
  align-items: center;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  border-bottom: 1px solid white;
}

.Banner-Image{
  display: flex;
  justify-content: center;
}

.banner-img {
  width: 300px;
  height: 40%;
}
.menu-toggle{
  display: none;
  color: white;
}

.hidden {
  display: none;
}

.side-menu {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;
  width: 75%;
  background-color: #121212;
  transition: left 1.0s;
  z-index: 1;
  color: white;
}

.side-menu.open {
  left: 0;
}

.Side-Menu-Close{
  float: right;
  margin-right: 20px;
  font-size: 30px;
  color: #BFC3B6;
  margin-top: 20px;
}

.Side-Menu-Close:hover{
  cursor: pointer;
}

.side-item {
  cursor: pointer;
  padding: 5px 10px;
  transform: scale(1);
  margin-left: 20px;
  margin-bottom: 15px;
  transition: transform 0.3s;
  color: #A2A2A2;
}

.side-item.active {
  color: orange;
}

.side-item:hover{
  transform: scale(1.05);
}

.Home-Search {
  display: none;
}

.Home-Search:hover{
  cursor: pointer;
}

.Home-Page-Banner-Bottom{
  display: flex;
  justify-content: center;
}

.Product-nav-bar{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 5px;
  color: #fff;
  width: 85%;
  margin-bottom: 10px;
}

.nav-item {
  cursor: pointer;
  padding: 5px 10px;
  transform: scale(1);
  font-size: 15px;
}

.nav-item::after {
  content: '';
  display: block;
  width: 0;
  margin-top: 4px;
  height: 2px;
  background: #F6A119;
  transition: width .3s;
}

.nav-item:hover::after {
    width: 100%;
}

.nav-item.active {
  color: orange;
}

.search{
  width: 30px;
  height:30px;
  margin-top: 10px;
}
.search:hover{
  cursor: pointer;
}

.Search-Page{
  width: 100%;
  height: 100%;
  background-color: white;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Search-Container{
  border: 1px solid #A2A2A2;
  width: 85%;
  height: 80%;
  justify-content: center;
}

.Search-Close{
  float: right;
  right: 10%;
  font-size: 40px;
  color: #A2A2A2;
  position: absolute;
}
.Search-Close:hover{
  cursor: pointer;
}

.Search-Input-Container{
  padding: 20px;
  width: 80%;
  height: 20%;
  margin-left: 5%;
}

.Search-Title{
  font-size: 30px;
  color: #535353;
}

.search-box {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input{
  border: none;
  border-bottom: 1px solid #BFC3B6;
  padding: 10px;
  color: #A2A2A2;
  font-size: 20px;
  width: 100%;
  outline: none;
}

.search-icon {
  position: absolute;
  right: 10px;
  background-image: url('/public/images/search.png');
  background-size: cover;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.Search-Display-Container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  height: 70%;
  width: 85%;
  margin-left: 5%;
}
.Search-Display-Count{
  width: 100%;
  margin-left: 5%;
  display: flex;
  font-size: 15px;
}
.Product-nav-bar img{
  width: 20px;
  height: 20px;
}
.Home-Page-Container{
  height: 65%;
  overflow-y: auto;
}

.Display—Header{
  text-align: center;
}

.Display-Count{
  margin-left: 10%;
  display: flex;
  font-size: 15px;
}

.Display-Count-Total{
  color: #A2A2A2;
  font-weight: bold;
}

.Display-Count-Number{
  margin-left: 10px;
  margin-right: 5px;
  color: black;
  font-weight: bolder;
}

.Home-Page-Display-Container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product{
  margin: 10px;
  width: 22%;
  justify-content: center;
}

.product-image{
  justify-content: center;
  align-items: center;
  display: flex;
}

.product-image img {
  width: 200px;
  max-height: 220px;
}

.product-info {
  margin-top: 10px;
  text-align: center;
  flex-wrap: wrap;
}

.product-code{
  color: red;
}

.More-Products{
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.More-Products button{
  width: 50vh;
  background: none;
  border-radius: 10px;
  font-size: 18px;
  transition: transform 0.3s;
}

.More-Products button:hover{
  width: 50vh;
  background: none;
  border-radius: 10px;
  font-size: 18px;
  transform: scale(1.02);
}

.Home-Page-Footer {
  background-color: black;
  min-height: 60vh;
  color: #BFC3B6;
  padding: 20px;
  font-family: 'Varela Round', monospace;
}

.Home-Page-Footer-Top {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #535353;
  padding-bottom: 20px;
}

.Footer-Top-Left{
  margin-left: 2%;
}

.Footer-Top-Right{
  margin-right: 2%;
}

.Footer-section{
  display: flex;
  align-items: center;
}

.Footer-section h3 {
  font-size: 1.2em;
  color: #535353;
  margin-right: 10px;
}

.Footer-section p {
  font-size: 1.2em;
  color: #A2A2A2;
  font-weight: bolder;
}


.Home-Page-Footer-Bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logo {
  width: 250px;
  height: 200px;
}

.Footer-Bottom-Left{
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.Footer-Bottom-Right{
  padding: 20px;
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-icons{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.contact-icons img{
  width: 40px;
  height: 40px;
  margin-right: 30px;
}

.Footer-Bottom-Right p{
  text-align: center;
  width: 100%;
}

.modal{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content{
  height: 60%;
  width: 25%;
  padding: 20px;
  background-color: whitesmoke;
  border-radius: 10px;
  position: relative;
  z-index: 1001;
  overflow-y: auto;
}

.modal-content img{
  height: 100%;
  width: 100%;
}
@media (max-width: 1100px) {
  .Home-Page{
    height: 100%;
  }
  .Home-Page-Footer-Top {
    display: flex;
    flex-direction: column;
  }
  .Home-Page-banner{
    height: 15%;
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    border-bottom: 1px solid white;
  }
  .banner-img{
    width: 200px;
    height: 100px;
  }
  .side-menu {
    display: block;
  }
  .side-menu img{
    width: 200px;
    height: 100px;
    margin: 20px;
  }
  .Home-Search {
    width: 28px;
    height: 28px;
    display: block;
    position: absolute;
    right: 20px;
    top: 40px;
  }
  .menu-toggle{
    display: block;
    position: absolute;
    top: 30px;
    left: 20px;
    font-size: 40px;
    color: #A2A2A2;
    cursor: pointer;
  }
  .Product-nav-bar{
    display: none;
  }
  .search{
    display: none;
  }
  .Footer-section {
    margin-bottom: 20px;
  }

  .product{
    width: 40%;
  }

  .Home-Page-Footer-Bottom {
    display: flex;
    flex-direction: column;
  }

  .Footer-Top-Right{
    margin-left: 2%;
  }
}

